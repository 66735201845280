import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userReducer } from "src/slices/user";
import { reducer as dashboardReducer } from "src/slices/dashboard";
import { reducer as campaignReducer } from "src/slices/campaign";
import { reducer as alertReducer } from "src/slices/alert";
import { reducer as brandReducer } from "src/slices/brand"
import { reducer as dynamicLinkReducer } from "src/slices/dynamicLink";
import { reducer as analyticsReducer } from "src/slices/analytics"
import { reducer as referralReducer } from "src/slices/referral"
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
  campaign: campaignReducer,
  alert: alertReducer,
  brand: brandReducer,
  dynamicLink: dynamicLinkReducer,
  analytics: analyticsReducer,
  referrals: referralReducer
});

const persistConfig = {
  key: "root",
  blacklist: ['campaign', 'alert'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
