
export interface Role {
  _id?: string;
  name: string;
  type: RoleType;
}

export enum AccountStatusEnum {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
	PENDING = "PENDING",
	ARCHIVED = "ARCHIVED"
}

export enum AccountTypeEnum {
	FRIENDVERTISER = "FRIENDVERTISER",
	CLIENT = "CLIENT",
	WYSPR = "WYSPR",
	UNDEFINED = "UNDEFINED"
}

export enum RoleType {
  BRAND_MANAGER = "Brand Manager",
	DIGITAL_MARKETER = "Digital Marketer",
	MARKETING_COORDINATOR = "Marketing Coordinator",
}

export enum GenderEnum {
	MALE = "MALE",
	FEMALE = "FEMALE",
  ALL = "ALL",
	OTHER = "OTHER"
}

export interface User {
  id?: string;
  email: string;
  first_name: string;
  last_name: string;
  clientTeam?: string,
  [key: string]: any;
}

export interface UserMetric {
  _id: string;
  data?: User[];
}

export interface SizeOrganisation {
  _id?: string;
  name: string;
  type: SizeOrganisationType;
}

export enum SizeOrganisationType {
  F0T3 = "F0T3",
  F4T10 = "F4T10",
  F11T50 = "F11T50",
  F51T250 = "F51T250",
  F251T1000 = "F251T1000",
  F1001 = "F1001",
}
